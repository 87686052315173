/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable css-modules/no-unused-class */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faInfoCircle, faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import Typography from 'components/atoms/Typography';
import TextField from 'components/molecules/TextField';
import ButtonSecondaryGhost from 'components/atoms/buttons/ButtonSecondaryGhost';
import Modal from 'components/organisms/Modal';
import { useSelector } from 'react-redux';
import Link from '../../atoms/Link';
import useTranslate from '../../../utils/hooks/useTranslate';
import useSubscribeNewsletter from '../../../utils/hooks/useSubscribeNewsletter';
import useAuth from '../../../utils/hooks/useAuth';
import useTranslation from '../../../utils/hooks/useTranslation';
import s from './FooterSubscribe.css';
import useUser from '../../../utils/hooks/useUser';
import useFeatureFlags from '../../../utils/hooks/useFeatureFlags';

const sectionParametersSelector = state => state?.cook?.data?.resolution?.parameters ?? {};

const FooterSubscribe = ({
  id,
  newsletterName = 'Nytt från Svenskt Näringsliv',
  apsisMailingListId: specifiedApsisMailingListId,
  mailchimpMailingListId: specifiedMailchimpMailingListId,
  mailjetMailingListId: specifiedMailjetMailingListId,
  mailjetAccount: specifiedMailjetAccount,
  hideHeadline,
}) => {
  const t = useTranslate();
  const { IS_SWEDISH } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { userQuery } = useUser();
  const featureFlags = useFeatureFlags();
  const sectionParameters = useSelector(sectionParametersSelector);

  let apsisMailingListId = specifiedApsisMailingListId;
  let mailchimpMailingListId = specifiedMailchimpMailingListId;
  let mailjetMailingListId = specifiedMailjetMailingListId;
  let mailjetAccount = specifiedMailjetAccount;

  if (!apsisMailingListId && !mailchimpMailingListId && !mailjetMailingListId) {
    apsisMailingListId = sectionParameters.sven_newsletter_footer_apsisMailingListId;
    mailchimpMailingListId = sectionParameters.sven_newsletter_footer_mailchimpMailingListId;
    mailjetMailingListId = sectionParameters.sven_newsletter_footer_mailjetMailingListId;
    mailjetAccount = sectionParameters.sven_newsletter_footer_mailjetAccount;
  }

  const shouldUseUserEmail =
    !featureFlags.disablePremiumContent && isAuthenticated && userQuery.data?.userData?.email;

  const [inputValue, setInputValue] = useState(
    shouldUseUserEmail ? userQuery.data?.userData?.email ?? '' : ''
  );

  const { onSubscribe, posting, subscriptionStatus, success, error, resetSubscribeNewsletter } =
    useSubscribeNewsletter({
      apsisMailingListId,
      mailchimpMailingListId,
      mailjetMailingListId,
      mailjetAccount,
    });

  const { register, handleSubmit, reset, errors } = useForm();

  const [state, setState] = useState({
    modalOpen: false,
    currentScroll: typeof window !== 'undefined' && window.scrollY,
  });

  useEffect(() => {
    if (shouldUseUserEmail && isAuthenticated && userQuery.data?.userData?.email) {
      setInputValue(userQuery.data?.userData?.email ?? '');
    } else {
      setInputValue('');
    }
  }, [shouldUseUserEmail, isAuthenticated, userQuery.data?.userData?.email]);

  useEffect(() => {
    if (typeof window !== 'undefined' && !state.modalOpen && state.email !== '') {
      // Disabling this because it clashes with hash-routing
      // setTimeout(() => {
      //   window.scroll(0, state.currentScroll);
      // }, 0);
    }
  }, [state.modalOpen]);

  const closeModal = () => {
    setState({ ...state, modalOpen: false });
    resetSubscribeNewsletter();
  };

  const subscribe = async formData => {
    const isOkResponse = await onSubscribe(formData[id], newsletterName);

    if (isOkResponse) reset();

    setState({
      ...state,
      modalOpen: true,
      currentScroll: typeof window !== 'undefined' && window.scrollY,
    });
  };

  if (!apsisMailingListId && !mailchimpMailingListId && !mailjetMailingListId) {
    return null;
  }

  return (
    <div className={s.footerSubscribe}>
      <Modal modalOpen={state.modalOpen} closeModal={closeModal} label={t('SUBSCRIBE.MODAL_LABEL')}>
        <div className={s.subscribeBox}>
          {success ? (
            <div className={s.successWrapper}>
              <div className={s.successIconWrapper}>
                {subscriptionStatus === 'ok' && (
                  <FontAwesomeIcon icon={faCheckCircle} className={s.successIcon} />
                )}
                {subscriptionStatus === 'pending' && (
                  <FontAwesomeIcon icon={faInfoCircle} className={s.pendingIcon} />
                )}
                {subscriptionStatus === 'tryAgain' && (
                  <FontAwesomeIcon icon={faExclamationCircle} className={s.warningIcon} />
                )}
                {subscriptionStatus === 'optOutAll' && (
                  <FontAwesomeIcon icon={faInfoCircle} className={s.pendingIcon} />
                )}
                <Typography variant="h4" component="div">
                  {subscriptionStatus === 'ok' && t('SUBSCRIBE.MODAL_OK_TITLE')}
                  {subscriptionStatus === 'pending' && t('SUBSCRIBE.MODAL_PENDING_TITLE')}
                  {subscriptionStatus === 'tryAgain' && t('SUBSCRIBE.MODAL_TRY_AGAIN_TITLE')}
                  {subscriptionStatus === 'optOutAll' && t('SUBSCRIBE.MODAL_OPT_OUT_TITLE')}
                </Typography>
              </div>
              <Typography>
                {subscriptionStatus === 'ok' && (
                  <>
                    {t('SUBSCRIBE.MODAL_OK')} <strong>{newsletterName}</strong>!
                  </>
                )}
                {subscriptionStatus === 'pending' && t('SUBSCRIBE.MODAL_PENDING')}
                {subscriptionStatus === 'tryAgain' && t('SUBSCRIBE.MODAL_TRY_AGAIN')}
                {subscriptionStatus === 'optOutAll' &&
                  t('SUBSCRIBE.MODAL_OPT_OUT_NEW', {
                    emaillink: email => (
                      <Typography href={`mailto:${email}`} component="a" variant="link">
                        {email}
                      </Typography>
                    ),
                  })}
              </Typography>
              <ButtonSecondaryGhost classes={s.closeButton} onClick={closeModal}>
                {t('SUBSCRIBE.MODAL_CLOSE')}
              </ButtonSecondaryGhost>
            </div>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {error && (
                <>
                  <Typography component="div" classes={s.error} gutterBottom>
                    <FontAwesomeIcon icon={faExclamationCircle} className={s.warningIcon} />
                    {t('SUBSCRIBE.MODAL_ERROR')}
                  </Typography>
                  <ButtonSecondaryGhost classes={s.closeButton} onClick={closeModal}>
                    {t('SUBSCRIBE.MODAL_CLOSE')}
                  </ButtonSecondaryGhost>
                </>
              )}
            </>
          )}
        </div>
      </Modal>
      {!hideHeadline && <div className={s.headline}>{t('SUBSCRIBE.TITLE')}</div>}
      <div className={s.emailWrapper}>
        <form autoComplete="on" onSubmit={handleSubmit(subscribe)}>
          <label htmlFor={id}>
            <span className={s.labelText}>{t('SUBSCRIBE.FILL_EMAIL')}</span>
            <TextField
              className={s.emailField}
              id={id}
              placeholder={t('SUBSCRIBE.ENTER_EMAIL')}
              label={t('SUBSCRIBE.EMAIL_LABEL')}
              gutterBottom={false}
              labelHidden
              type="email"
              errorMessage={errors?.email?.message}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              register={register({
                required: t('SUBSCRIBE.EMAIL_MISSING'),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t('SUBSCRIBE.EMAIL_INVALID'),
                },
              })}
            />
          </label>
          <button className={s.submitButton} type="submit" disabled={posting}>
            {posting && <div className={s.ldsDualRing} />}
            {t('SUBSCRIBE.BUTTON_LABEL_SUBSCRIBE')}
          </button>
        </form>
      </div>
      {isAuthenticated && shouldUseUserEmail && (
        <Typography variant="contentTypeDate" component="p" className={s.prefilledEmailInfo}>
          <FontAwesomeIcon icon={faCircleInfo} />
          <span>
            {t('SUBSCRIBE.MODAL_PREFILLED_EMAIL', {
              link: linkText => (
                <Link
                  // TODO: Add correct link url
                  to={IS_SWEDISH ? '/dataskydd/' : '/english/data-protection/'}
                  target="_blank"
                  className={s.termsLink}
                >
                  {linkText}
                </Link>
              ),
            })}
          </span>
        </Typography>
      )}
    </div>
  );
};

export default withStyles(s)(FooterSubscribe);
