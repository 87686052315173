/* eslint-disable camelcase */
/* eslint-disable css-modules/no-undef-class */
import React, { Fragment } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import Typography from 'components/atoms/Typography';
import getImages from 'utils/getImages';
import generateSizes from 'utils/generateSizes';
import { getHeightByWidthAndAspectRatio } from 'utils/getImageSizeByAspectRatio';
import s from './InlineImage.css';
import Translate from '../../../containers/atoms/TranslateContainer';
import Link from '../../../containers/atoms/LinkContainer';

const InlineImage = ({
  classes,
  imageSrc,
  imageAlt,
  caption,
  photographer,
  leftAligned,
  rightAligned,
  pushRight,
  darkMode,
  useFreeform: DEPRECATED_useFreeform,
  noMarginBottom,
  fixedImageVersion,
  rawImageUrl,
  width,
  height,
  href,
}) => {
  const useFreeform =
    (DEPRECATED_useFreeform && fixedImageVersion === 'useCueImageVersions') ||
    fixedImageVersion === 'useFreeform';

  let sizes;
  if (useFreeform) {
    sizes = getImages('FREEFORM');
  } else {
    sizes = leftAligned || rightAligned ? getImages('PORTRAIT') : getImages('LANDSCAPE');
  }
  const baseWidth = 170;

  const imageSizes =
    leftAligned || rightAligned
      ? generateSizes({
          lg: `${baseWidth}px`,
          md: `${baseWidth}px`,
          sm: `${baseWidth}px`,
          default: `${baseWidth}px`,
        })
      : generateSizes({ lg: '674px', sm: '62vw', default: '100vw' });

  const heightForLeftOrRightAlignedImages = getHeightByWidthAndAspectRatio(baseWidth, 'PORTRAIT', 'M');
  const forceLeft = rightAligned && caption && caption.length > 75;
  const isSvg = rawImageUrl && rawImageUrl.endsWith('.svg');
  const classNames = classnames(
    classes,
    s.inlineImage,
    rightAligned && s.rightAligned,
    leftAligned && s.leftAligned,
    (leftAligned || rightAligned) && pushRight && s.pushRight,
    forceLeft && s.forceLeft,
    noMarginBottom && s.noMarginBottom
  );

  const Wrapper = href ? Link : Fragment;

  return (
    <div className={classNames}>
      <Wrapper to={href} target={href ? '_blank' : undefined}>
        {fixedImageVersion === 'useOriginalImage' || isSvg ? (
          <img
            width={width}
            height={height}
            src={rawImageUrl}
            alt={imageAlt}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        ) : (
          <ResponsiveImage
            src={sizes.toSrc(imageSrc, 'M')}
            srcSet={sizes.toSrcSet(imageSrc)}
            sizes={imageSizes}
            alt={imageAlt}
            width={leftAligned || rightAligned ? baseWidth : width}
            height={leftAligned || rightAligned ? heightForLeftOrRightAlignedImages : height}
            lazyLoadWrapperHeight={leftAligned || rightAligned ? heightForLeftOrRightAlignedImages : null}
          />
        )}
      </Wrapper>
      {(caption || photographer) && (
        <div className={s.imageText}>
          {caption && (
            <Typography
              variant="imageCaption"
              component="div"
              classes={darkMode ? s.captionDarkMode : s.caption}
              darkMode={darkMode}
            >
              {caption}
            </Typography>
          )}
          {photographer && (
            <Typography variant="imageCaption" component="div" classes={s.photographer} darkMode={darkMode}>
              <Translate>IMAGE.PHOTO</Translate>: {photographer}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default withStyles(s)(InlineImage);
